.balance {
  padding-left: 1em;
  padding-right: 1em;
  flex-grow: 1;
  flex-basis: 33%;
  display: flex;
  flex-direction: column;
}

.balanceheader {
  display: flex;
  height: 1.5em;
  align-items: center;
  margin-bottom: 0.7em;
}

.balancelabel {
  flex-basis: 100%;
  font-weight: bold;
  text-align: center;
}

.balance select {
  border: none;
  border-bottom: 1px solid #888;
  font-size: medium;
  margin-top: -2px;
}

.balance select:focus-visible {
  border: none;
  border-bottom: 1px solid #888;
  outline: none;
}

.balance ul {
  padding-left: 20px;
}

.balance li {
  list-style-type: square;
}

.balance li .plusButton, .balance li .minusButton {
  float: right;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
  color: #444;
}

.balance li span .buttonFocused, .balance li span:hover .buttonUnfocused {
  display: none;
}

.balance li span:hover .buttonFocused, .balance li span .buttonUnfocused {
  display: block;
}

.balance .balanceTransaction {
  margin-top: 2px;
  color: #888;
  border-bottom: 1px solid #aaa;
  width: 100%;
}

.balance .history {
  text-align: right;
  color: #888;
}

.balance .historyrow {
  border-top: 1px dotted #888;
}

.balance .historyrow:hover {
  color: #000;
  background-color: #f0f0f0;
  cursor: pointer;
}

.balance .history .historyitem {
  color: #444;
}

.balance .empty {
  font-style: italic;
  margin-block-start: 1em;
  margin-block-end: 1em;
  text-align: center;
}

.balance .worth {
  font-style: italic;
  color: #444;
  margin-bottom: 0.5em;
  margin-top: -0.5em;
}

@scope (.balance .summary) {
  :scope {

  }

  .summarygroup {
    margin-top: 1em;

    & .groupname {
      color: #888;
    }

    & .accounts {
      margin-left: 4px;

      & .account {
        display: flex;

        & .accountname {
          flex-grow: 1;
        }
      }
    }
  }

}