.dialogwrapper {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 2000;
  position: fixed;
  background-color: rgba(0,0,0,.3);
  padding-top: 2vh;

  .dialogmodal {
    width: 95vw;
    max-width: 800px;
    max-height: 96%;
    position: relative;
    overflow: auto;
    border-radius: 4px;
    margin: 0 auto;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
  }
}

@scope (.dialogmodal header) {
  :scope {
    min-height: 3em;
    height: 3em;
    color: black;
    background-color: #f8f8f8;
    display: grid;
    padding-left: 1em;
    padding-right: 1em;
    align-items: center;
    grid-template-columns: auto;
  }

  h4 {
    margin: 0;
    text-align: left;
  }
  
  h4 .dim {
    float: right;
    cursor: pointer;
    color: #888;
  }
  
  h4 .dim:hover {
    color: black;
  }
}

@scope (.dialogmodal footer) {
  :scope {
    height: auto;
    border-top: solid 1px #cccccc;
    padding: 1em;
    background-color: #f8f8f8;
    display: flex;
  }

  .left {
    text-align: left;
    display: flex;
    gap: 1em;
    align-items: center;
  }

  .right {
    justify-content: flex-end;
    flex-grow: 1;
    display: flex;
    gap: 1em;
    align-items: center;
  }

  button {
    background-color: white;
    border: 1px solid #444;
    font-size: 100%;
    padding: 0.5em;

    &:hover:not(:disabled) {
      background-color: #444;
      color: white;
      cursor: pointer;
    }
  }

  span {
    color: #c44;
  }
}

.dialogmodal .dialogchildren.hidden {
  display: none;
}