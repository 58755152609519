.useaction {
  flex: 1;
  text-align: left;
  overflow: auto;
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.useaction .section {
  /*border-bottom: 1px solid #ccc;*/
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  flex-direction: column;
}

.useaction .section .sectionname {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  color: #444;
  margin-bottom: 0.5em;
}

.useaction .type {
  display: flex;
  flex-direction: row;
}

.useaction .type div {
  flex-basis: 50%;
  flex-grow: 1;
  margin: 0.3em;
}

.useaction .type .typeamount {
  text-align: right;
}

.useaction .type .typeamount input {
  width: 4em;
  text-align: right;
}

.useaction div.addtype {
  margin-top: 0.5em;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
}

.useaction div.addtype > * {
  margin: 0.3em;
}

.useaction div.addtype select {
  font-size: 18px;
  border: none;
  border-bottom: 1px solid #888;
  cursor: pointer;
}

.useaction * > .buttonFocused:not(:disabled), .useaction *:hover > .buttonUnfocused {
  display: none;
}

.useaction *:hover > .buttonFocused:not(:disabled), .useaction * .buttonUnfocused {
  display: inline-block;
}

.useaction textarea.additionalcomment {
  font-size: 14px;
  padding: 0.5em;
  margin-top: 1em;
  border: 1px solid #aaa;
  flex-shrink: 0;
}

.useaction textarea.additionalcomment::placeholder {
  color: #ccc;
}