.selectaction {
  flex: 1;
  text-align: left;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.selectaction .section .header {
  font-weight: bold;
  font-size: 17px;
  color: #444;
  padding-left: 0.75em;
  border-bottom: 1px solid #ccc;
}

.selectaction .section .option {
  border-bottom: 1px solid #ccc;
  padding: 0.75em;
  cursor: pointer;
  background-color: #f8f8f8;
}

.selectaction .section .option:hover {
  background-color: #444;
  color: white;
}

.selectaction .section {
  margin-top: 0.75em;
}

