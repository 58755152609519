.editaction {
  flex: 1;
  text-align: left;
  overflow: auto;
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.editaction .header {
  font-weight: bold;
  font-size: 17px;
  color: #444;
}

.editaction .name input.actionname {
  font-size: 120%;
}

.editaction .name input.actionname, .editaction .text input.transactiontext {
  margin-top: 2px;
  width: 100%;
  margin-bottom: 1em;
  box-sizing: border-box;
}

.editaction .section {
  border: 1px solid #aaa;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
}

.editaction .section .nontypecol, .editaction .section .typecol {
  flex-grow: 1;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.editaction .section input, .editaction .section select {
  width: 100%;
  box-sizing: border-box;
}

.editaction .section select {
  border: none;
  border-bottom: 1px solid #888;
}

.editaction .subheader {
  font-weight: bold;
  font-size: 15px;
  color: #444;
}

.editaction .iconbutton {
  padding-right: 0.5em;
  cursor: pointer;
}

.editaction .sections .header .iconbutton {
  padding-left: 1em;
}

.editaction .section .deletecol {
  padding-top: 0.5em;
  padding-left: 0.5em;
}

.editaction .iconbutton > .buttonFocused:not(:disabled), .editaction .iconbutton:hover > .buttonUnfocused {
  display: none;
}

.editaction .iconbutton:hover > .buttonFocused:not(:disabled), .editaction .iconbutton .buttonUnfocused {
  display: inline-block;
}

.dialogmodal button.deleteaction {
  background-color: #ebb;
}

.dialogmodal button.deleteaction:hover {
  color: #ebb !important;
}