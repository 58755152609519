.configuration {
  flex: 1;
  text-align: left;
  overflow: auto;
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.configuration .header {
  font-weight: bold;
  font-size: 17px;
  color: #444;
}

.configuration .name input.ledgername {
  margin-top: 2px;
  font-size: 120%;
  width: 100%;
}

.configuration .accounts, .configuration .types, .configuration .accountgroups, .configuration .virtualaccounts {
  margin-top: 1em;
}

.configuration .accountrowheader, .configuration .virtualaccountrowheader, .configuration .typerowheader {
  border-bottom: 1px solid #ccc;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-weight: bold;
}

.configuration .headeraddbutton {
  margin-left: 1em;
  cursor: pointer;
}

.configuration .headeraddbutton > .buttonFocused:not(:disabled), .configuration .headeraddbutton:hover > .buttonUnfocused {
  display: none;
}

.configuration .headeraddbutton:hover > .buttonFocused:not(:disabled), .configuration .headeraddbutton .buttonUnfocused {
  display: inline-block;
}

.configuration .newaccount, .configuration .newtype, .configuration .newgroup, .configuration .newvirtualaccount {
  display: flex;
  flex-direction: row;
}

.configuration .newaccount .newaccountname, .configuration .newtype .newtypename, .configuration .newgroup .newgroupname, .configuration .newvirtualaccount .newvirtualaccountname {
  flex-basis: 80%;
}

.configuration .newaccount button, .configuration .newtype button, .configuration .newgroup button, .configuration .newvirtualaccount button {
  flex-basis: 20%;
  background-color: white;
  cursor: pointer;
  border: 1px solid #444;
  border-radius: 2px;
}

.configuration .newaccount button:hover, .configuration .newtype button:hover, .configuration .newgroup button:hover, .configuration .newvirtualaccount button:hover {
  background-color: #444;
  color: white;
}

.configuration .accountrow, .configuration .virtualaccountrow, .configuration .typerow {
  display: flex;
  flex-direction: row;
  column-gap: 4px;
}

.configuration .accountrow:not(.accountrowheader):hover, .configuration .typerow:not(.typerowheader), .configuration .accountgroups .group:hover, .configuration .virtualaccountrow:not(.virtualaccountrowheader):hover {
  background-color: #eee;
}

.configuration .accountrow .name, .configuration .virtualaccountrow .name, .configuration .typerow .name {
  flex-grow: 1;
}

.configuration .virtualaccountrow .plusaccount, .configuration .virtualaccountrow .minusaccount, .configuration .typerow .worth {
  width: 10em;
}

.configuration .accountrow .group select, .configuration .virtualaccountrow .plusaccount select, .configuration .virtualaccountrow .minusaccount select {
  width: 100%;
}

.configuration .types .header, .configuration .accountgroups .header {
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.5em;
}
