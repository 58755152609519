.newledgercontainer {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: auto;
  font-size: 24px;
  height: 100vh;
}

.newledgercontainer .spacer {
  flex-grow: 1;
}

.newledgercontainer .newledgerform {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.newledgercontainer .newledgerinputcolumn {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.newledgercontainer .newledgerinputrow {
  display: flex;
  flex-direction: row;
}

.newledgercontainer .newledgerinputrow:nth-child(2) {
  color: #444;
  font-size: 20px;
  margin-top: 5px;
}

.newledgercontainer .newledgerbuttoncolumn {
  display: flex;
  height: 100%;
}

.newledgercontainer span {
  flex-grow: 0;
  flex-shrink: 0;
}

.newledgercontainer input {
  font-size: 24px;
  margin-left: 0.5em;
  flex-grow: 1;
  height: 26px;
  border: none;
  border-bottom: 1px solid #444;
  width: 100%;
}

.newledgercontainer select {
  font-size: 20px;
  color: #444;
  margin-left: 0.5em;
  flex-grow: 1;
  border: none;
  border-bottom: 1px dashed #444;
  cursor: pointer;
}

.newledgercontainer button {
  font-size: 24px;
  background-color: white;
  border: 1px solid #444;
  margin-left: 0.5em;
  flex-grow: 0;
  cursor: pointer;
  border-radius: 2px;
}

.newledgercontainer button:hover {
  color: white;
  background-color: #444;
}

.newledgercontainer button.disabled {
  background-color: white;
  border: 1px solid #444;
  color: #aaa;
  cursor: default;
}

.newledgerreminder {
  text-align: center;
  font-size: 20px;
  font-style: italic;
  color: #444;
  margin-top: 6px;
}
