.message {
  text-align: center;
}

div.ledgerheading {
  box-sizing: border-box;
  text-align: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  font-size: 200%;
  font-weight: bold;
  margin-bottom: 20px;
}

div.ledgerheading span.buttons {
  float: right;
  color: #ccc;
  width: 40px;
  font-size: 80%;
  padding-top: 6px;
}

div.ledgerheading span.buttons:hover {
  color: #444;
  cursor: pointer;
}

.tabs {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
}

.helpfulhint {
  text-align: center;
  font-style: italic;
  color: #444;
}

.transactions {
  flex-grow: 2;
  flex-basis: 67%;
  display: flex;
  flex-direction: column;
}

.debitcreditheader {
  display: flex;
  flex-direction: row;
  padding-left: 1em;
  padding-right: 1em;
  height: 1.5em;
  align-items: center;
  margin-bottom: 0.7em;
}

.debitcreditheader div {
  flex-grow: 1;
  flex-basis: 50%;
  text-align: center;
  font-weight: bold;
}

.debitcreditheader div.buttons {
  flex: 0 0 4em;
  font-size: 120%;
  color: #444;
  cursor: pointer;
  z-index: 100;
}

.debitcreditheader div.buttons svg {
  margin: auto;
}

.debitcreditheader div.buttons .buttonFocused, .debitcreditheader div.buttons:hover .buttonUnfocused {
  display: none;
}

.debitcreditheader div.buttons:hover .buttonFocused, .debitcreditheader div.buttons .buttonUnfocused {
  display: block;
}

.debitcreditheader .debitlabel {
  margin-right: -2em;
}
.debitcreditheader .creditlabel {
  margin-left: -2em;
}

.transaction {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding-left: 1em;
  padding-right: 1em;
  display: flex;
  flex-direction: column;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  cursor: pointer;
}

.transaction:nth-child(2) {
  padding-top: 0em;
}

.transaction.nonposting {
  background-color: #333;
  color: #fff;

  & .transheader .editbutton {
    color: #eee;
  }

  & .transheader .date {
    color: #999;
  }

  & .transdetails .account {
    color: #ddd;
  }

  & .transcomment {
    color: #999;
  }

  & .transheader .text::before {
    content: 'Non-posting:';
    margin-right: 0.5em;
    color: #aaa;
    font-weight: bold;
  }

  &:hover {
    background-color: #555;
  }

  & .transheader {
    border-bottom: 1px solid #ccc;
  }

  & .transdetails .debit {
    border-right: 1px solid #888;
  }
}

.transaction:hover {
  background-color: #f0f0f0;
}

.transheader {
  border-bottom: 1px solid #888;
}

.transheader .editbutton {
  display: none;
  padding-right: 0.5em;
  color: #444;
  font-size: 14px;
}

.transheader .date {
  color: #888;
  float: right;
}

.transaction:hover .editbutton {
  display: inline-block;
}

.transheader .nonposting {
  font-weight: bold;
}

.transdetails {
  display: flex;
  flex-direction: row;
}

.transdetails .debit, .transdetails .credit {
  flex-grow: 1;
  flex-basis: 50%;
}

.transdetails .debit {
  border-right: 1px solid #ccc;
  padding-right: 1em;
}

.transdetails .credit {
  padding-left: 1em;
}

.transdetails .debit div, .transdetails .credit div {
  margin-top: 0.25em;
  display: flex;
  justify-content: space-between;
}

.transdetails .account {
  color: #333;
  flex-basis: 60%;
  flex-grow: 1;
}

.transdetails .account.selectedBalance {
  font-weight: bold;
}

.transdetails .typeamount {
  flex-basis: 40%;
  flex-grow: 1;
  text-align: right;
}

.transactions .transcomment {
  text-align: center;
  color: #888;
  font-style: italic;
  font-family: 'Optima', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}