.edittransaction {
  flex: 1;
  text-align: left;
  overflow: auto;
  padding: 1em;
  display: flex;
  flex-direction: column;

  .dialogmodal:has(&.nonposting) header {
    background-color: #333;
    color: #eee;
  }

  .dialogmodal:has(&.nonposting) header h4 span::before {
    color: #aaa;
    content: 'Non-posting';
    margin-right: 1em;
  }

  &.moving {
    opacity: 0.3;
    pointer-events: none;
  }

  .dialogmodal:has(&.moving) {
    background-color: #888;
  }
}

.edittransaction .topinputs {
  display: flex;
  flex-direction: row;
}

.edittransaction input.transactiontext {
  flex-grow: 1;
  margin: 0;
  font-size: 100%;
  padding: 0.5em;
}

.edittransaction input.customdate {
  width: 100px;
  margin: 0;
  font-size: 100%;
  padding: 0.5em;
}

.edittransaction .typerow {
  border-bottom: 1px solid #ccc;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.edittransaction .typerow .typename {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  color: #444;
}

.edittransaction .typedebitcreditlabel {
  display: flex;
  flex-direction: row;
  margin-top: 0.5em;
}

.edittransaction .typedebitcreditlabel div {
  flex-basis: 50%;
  text-align: center;
  font-size: 18px;
  text-decoration: underline;
}

.edittransaction .typerow .typedebitcredit {
  display: flex;
  flex-direction: row;
}

.edittransaction .typerow .typedebitcredit div.typedebit {
  flex-basis: 50%;
  margin-right: 0.5em;
}

.edittransaction .typerow .typedebitcredit div.typecredit {
  flex-basis: 50%;
  margin-left: 0.5em;
}

.edittransaction .typerow .typedebitcredit .typerowitem {
  display: flex;
  margin-top: 4px;
  margin-bottom: 4px;
  align-items: center;
}

.edittransaction .typerow .typedebitcredit .typerowitem select {
  flex-basis: 90%;
  border: none;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  font-size: 18px;
}

.edittransaction .typerow .typedebitcredit .typerowitem div {
  flex-basis: 65%;
  font-size: 18px;
}

.edittransaction .typerow .typedebitcredit .typerowitem input {
  flex-basis: 25%;
  width: 0px;
  text-align: right;
  font-size: 18px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 20px;
  border: none;
  border-bottom: 1px solid #888;
}

.edittransaction .typerow .typedebitcredit .typerowitem span.removeaccountbutton {
  flex-basis: 10%;
  text-align: center;
  font-size: 18px;
  color: #888;
}

.edittransaction .typerow .typedebitcredit .typerowitem span.removeaccountbutton:hover {
  color: #444;
}

.edittransaction div.addtype {
  margin-top: 0.5em;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
}

.edittransaction div.addtype > * {
  margin: 0.3em;
}

.edittransaction div.addtype select {
  font-size: 18px;
  border: none;
  border-bottom: 1px solid #888;
  cursor: pointer;
}

.edittransaction span.addaccounticon {
  color: #888;
  flex-basis: 10%;
  text-align: center;
  font-size: 18px;
}

.edittransaction * > .buttonFocused:not(:disabled), .edittransaction *:hover > .buttonUnfocused {
  display: none;
}

.edittransaction *:hover > .buttonFocused:not(:disabled), .edittransaction * .buttonUnfocused {
  display: inline-block;
}

.edittransaction textarea.transactioncomment {
  font-size: 14px;
  padding: 0.5em;
  margin-top: 1em;
  border: 1px solid #aaa;
  flex-shrink: 0;
}

.edittransaction textarea.transactioncomment::placeholder {
  color: #ccc;
}

.edittransaction .addtype .addnewtypebutton, .edittransaction .typerowitem .addnewaccountbutton {
  background-color: white;
  border: 1px solid #444;
  vertical-align: middle;
} 

.edittransaction .addtype .addnewtypebutton:hover, .edittransaction .typerowitem .addnewaccountbutton:hover {
  background-color: #444;
  color: white;
  cursor: pointer;
} 

.edittransaction .typerow .typedebitcredit .typerowitem input.addnewaccountname {
  text-align: left;
  flex-grow: 1;
}

.dialogmodal button.deletetrans {
  background-color: #ebb;
}

.dialogmodal button.deletetrans:hover {
  color: #ebb !important;
}